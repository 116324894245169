<template>
    <v-btn depressed
    class="mr-2"
    :disabled="!activationStore.isActivated"
    color="secondary" @click="create">
      Publish Trips
      <v-icon right dark> mdi-publish </v-icon>
    </v-btn>
  </template>
  
  <script>
  import { activationStore } from "@/utils/helpers";
  export default {
    name: "PublishTripsButton",
    setup() {
      return { activationStore }
    },
    methods: {
      create() {
        this.$emit("create");
      }
    }
  };
  </script>
  
